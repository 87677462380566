import React from 'react'

import './index.scss'

export const Footer = () => (
  <footer className="footer">
    Copyright©2021{' '}
      | syd-dev.com
    <br />
      Happy Coding 😊
  </footer>
)
